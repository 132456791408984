.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: var(--primary-secondary);
  padding: 1rem 2rem;
  border-radius: 10px;
  box-sizing: border-box;
  max-height: 15rem;
  min-height: 15rem;
}

.details {
  align-self: center;
}

.pay {
  font-size: var(--font-900);
  font-weight: bold;
}

.hours {
  color: var(--text-accent);
  text-align: right;
}

.percentage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.positive {
  color: var(--accent-green);
}

.negative {
  color: var(--accent-orange);
}

.baseline {
  color: var(--accent-yellow);
}
