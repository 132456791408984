.calendar {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  text-align: center;
  margin-bottom: 1.5rem;
}

.year {
  color: var(--text-accent);
}

.prev,
.next {
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: var(--font-700);
  color: var(--text-accent);
}

.prev:hover,
.next:hover {
  cursor: pointer;
}

.dates {
  display: grid;
  grid-template-rows: repeat(6, auto);
  grid-template-columns: repeat(7, 1fr); 
  row-gap: 1.5rem;
  column-gap: clamp(0.25rem, 2%, 1rem);
  justify-content: center;
}

.weekday {
  width: 40px;
  text-align: center;
}


.day {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  z-index: 2;
}


.day::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: background-color 0.1s;
  z-index: -1;
  aspect-ratio: 1/1;
}

.outside,
.grayed {
  color: var(--text-accent);
}
.selected::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary);
}

.day:hover::before {
  background-color: var(--primary);
}

.day:hover {
  cursor: pointer;
}

.payslip::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  z-index: -1;
  background-color: var(--accent-green);
}

.onshift::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  z-index: -1;
  background-color: var(--accent-orange);
}

.today {
  color: var(--secondary);
  aspect-ratio: 1/1;
}

.today::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--text-primary);
  aspect-ratio: 1/1;
  z-index: -1;
}

.holiday::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  z-index: -1;
  background-color: var(--accent-yellow);
}

.payslipleft::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 100%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  z-index: -1;
  background-color: var(--accent-green);
}

.shiftright::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 100%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  z-index: -1;
  background-color: var(--accent-orange);

}

.payhol::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 100%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  z-index: -1;
  background-color: var(--accent-green);
}

.payhol::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 100%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  z-index: -1;
  background-color: var(--accent-orange);

}
