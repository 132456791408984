@keyframes slideInLeft {
  0% {
    transform: translateX(-100%) translateY(-50%);
  }
  100% {
    transform: translateX(-50%) translateY(-50%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(0%) translateY(-50%);
  }
  100% {
    transform: translateX(-50%) translateY(-50%);
  }
}

@keyframes blur {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.show {
  display: flex;
}

.right {
  animation: slideInLeft 0.7s ease-out, blur 0.5s ease-out;
}

.left {
  animation: slideInRight 0.7s ease-out, blur 0.5s ease-out;
}


