.container {
  padding: 1rem 2rem;
  background-color: var(--primary);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-secondary);
  max-width: 15.625rem;
  width: 100%;
}

.header {
  margin-bottom: 1rem;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.nextDate {
  text-align: center;
}

.dayName,
.month {
  font-size: var(--font-500);
}

.day {
  font-size: var(--font-700);
  font-weight: bold;
  color: var(--accent-orange);
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1.5;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}


.hours {
  color: var(--text-accent);
}

.timer {
  display: flex;
  justify-content: space-between;
}

.section {
  text-align: center;
}

.value {
  font-size: var(--font-500);
}

.name {
  color: var(--text-accent);
  font-size: var(--font-300);
}
