.container {
  background-color: var(--primary-secondary);
  padding: 1rem 2rem;
  border-radius: 10px;
  height: 18.75rem;
  position: relative;
}

.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: space-between;
  gap: 2rem;
}

.pcontainer {
  flex: 1;
  width: 170px;
}

.scontainer {
  flex: 3;
  min-width: 250px;
}

.payslip {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  flex: 1;
}

.date {
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: space-between;
}

.date>button {
  background-color: transparent;
  border: none;
  font-size: var(--font-700);
  color: var(--text-accent);
  padding: 0 1rem;
}

.header {
  text-align: center;
}

.year {
  color: var(--text-accent);
  text-align: center;
}

.total {
  color: var(--accent-green);
  font-size: var(--font-800);
  font-weight: bold;
}

.info {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.info>ul {
  list-style: none;
}

.values {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
}

.label {
  color: var(--text-accent);
}

.values {
  text-align: left;
}

.record {
  height: 100%;
}

.record>select {
  background-color: var(--primary);
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 10px;
}

.record>select:hover {
  cursor: pointer;
}

.grid {
  grid-template-columns: 1fr;
  margin-top: 2rem;
  overflow-y: auto;
  height: 70%;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-right: 3rem;
}

.box {
  text-align: center;
  padding: 1rem;
  background-color: var(--primary-tertiary);
  margin-right: 1rem;
  border-radius: 10px;
}

.day {
  font-size: var(--font-500);
  font-weight: bold;
  color: var(--accent-orange);
}

.date {
  font-size: var(--font-350);
}

.details {
  flex-grow: 2;
}

.shiftHeader {
  display: flex;
  justify-content: space-between;
}

.dropdown {
  font-size: var(--font-800);
  align-self: baseline;
}

.money {
  display: flex;
  justify-content: space-between;
}

.hours {
  color: var(--text-accent);
}

.amount {
  font-size: var(--font-500);
  color: var(--accent-green);
  font-weight: bold;
}

.optcontainer {
  position: relative;
  display: inline-block;
}

/* Styling the options menu */
.optbtn {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--primary);
  padding: 10px;
  z-index: 100;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.optbtn button {
  background: none;
  border: none;
  padding: 8px 12px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: var(--text-primary);
  transition: background-color 0.3s;
}

.optbtn button:hover {
  background-color: var(--primary-secondary);
}

.hide {
  display: none;
}
