.time {
  font-weight: bold;
  font-size: var(--font-500);
  min-width: 140px;
}

.hours {
  color: var(--text-accent);
}

