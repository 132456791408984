.sidebar {
  height: 100vh;
  background-color: var(--secondary);
  padding-right: 2rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  transition: width 0.3s ease, padding 0.3s ease; /* Transition for width and padding */
}

.expanded {
  width: 21.875rem; /* Sidebar expanded width */
  min-width: 20rem;
}

.collapsed {
  width: 0;
  padding: 0; /* Collapse both width and padding */
}

.container {
  margin-top: 3rem;
  overflow-y: auto;
}

.space {
  margin-bottom: 1rem;
}

.shifts {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  margin-right: 1.2rem;
  margin-left: 1.2rem;
}

.toggleButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 10px;
  display: block;
}


.cal {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1rem;
  margin-top: 1rem;
}

.hide {
    display: none;
}

@media (max-width: 480px) {
  .sidebar {
    position: absolute;
    width: 100%; /* Full width when open */
    top: 0;
    right: 0;
    transition: width 0.3s ease, padding 0.3s ease; /* Ensure transition is smooth */
    box-sizing: border-box;
  }

  .collapsed {
    width: 0; /* Collapse smoothly */
    padding: 0; /* Transition padding for smoother collapse */
  }
}

@media (max-width: 768px) {
  .sidebar {
    position: absolute;
    top: 0;
    right: 0;
  }
}
