.day {
  font-size: var(--font-700);
  font-weight: bold;
  color: var(--accent-green);
}

.cash {
  font-weight: bold;
  font-size: var(--font-500);
  color: var(--accent-green);
  width: 140px;
}
