.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-right: 3rem;
}

.box {
  text-align: center;
  padding: 1rem;
  background-color: var(--primary-tertiary);
  margin-right: 1rem;
  border-radius: 10px;
}

.day {
  font-size: var(--font-500);
  font-weight: bold;
  color: var(--accent-orange);
}

.date {
  font-size: var(--font-350);
}


.details {
  flex-grow: 2;
}

.header {
  display: flex;
  justify-content: space-between;
}

.dropdown {
  font-size: var(--font-800);
  align-self: baseline;
}

.money {
  display: flex;
  justify-content: space-between;
}

.hours {
  color: var(--text-accent);
}

.amount {
  font-size: var(--font-500);
  color: var(--accent-green);
  font-weight: bold;
}

.options {
  position: relative;
  display: inline-block;
}

.dropdown {
  position: absolute;
  right: 0;
  top: 100%; 
  background-color: var(--primary);
  padding: 10px;
  z-index: 100; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.dropdown button {
  background: none;
  border: none;
  padding: 8px 12px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: var(--text-primary);
}

.dropdown button:hover {
  background-color: var(--primary-secondary);
}

.hide {
  display: none;
}

