.container {
    height: 100vh;
}

nav {
    height: 100%;
}

.nav {
    height: 100%;
    padding: 0 2.5rem;
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: width 0.3s;
}

.logo {
    height: 25vh;
    font-weight: bold;
    margin-top: 3rem;
    font-size: var(--font-700);
}

.anchors {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 100%;
}

.anchor {
    all: unset;
}

.anchor>p {
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s;
}

.anchor>p:hover {
    color: var(--accent-orange);
    text-decoration: underline;
    cursor: pointer;
}

.controls {
    display: flex;
    justify-content: space-between;
}

.controls>button {
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: var(--text-accent);
    font-weight: bold;
    margin-bottom: 2rem;
}

.expanded {
    width: 12.5rem;
    transition: width 0.3s;
    background-color: var(--secondary);
}

.collapsed {
    width: 0;
    height: 0;
    padding: 0;
}

.toggleButton {
    display: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
}

.icon {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 1rem;
    margin-top: 1rem;
    height: fit-content;
}


.show {
    position: absolute;
    top: 0;
    left: 0;
}
