
.container {
  padding-left: 2rem;
  padding-right: 2rem;
}


.credentials {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 80%;
}

h1 {
  margin-bottom: 2rem;
}

input {
  font-size: var(--font-400);
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  background-color: var(--secondary);
  border: 2px solid var(--secondary);
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
  color: var(--text-primary);
  box-sizing: border-box;
}

button {
  padding: 1rem 3rem;
  background-color: var(--accent-green);
  border: none;
  color: var(--secondary);
  border-radius: 5px;
}

button:hover {
  cursor: pointer;
}

button:active {
  opacity: .5;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.continue {
  order: 1;
}

.prev {
  order: 0;
  background-color: var(--accent-orange);
}

.error {
  border: 2px solid var(--accent-red);
}

.hide {
  display: none;
}
