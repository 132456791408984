.container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 4rem;
}

.step-indicator {
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.step {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.step-indicator .step-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: var(--text-accent);
  font-size: 10px;
  text-align: center;
  color: var(--primary);
  position: relative;
  line-height: 50px;
  font-size: 20px;
}

.step.active .step-icon {
  background: var(--accent-green);
}

.step p {
  text-align: center;
  position: absolute;
  bottom: -40px;
  color: var(--text-accent);
  font-size: 14px;
  font-weight: bold;
}

.step.active p {
  color: var(--accent-green);
}

.step.step2 p,
.step.step3 p {
  left: 50%;
  transform: translateX(-50%);
}

.indicator-line {
  width: 100%;
  height: 2px;
  background: var(--text-accent);
  flex: 1;
}

.indicator-line.active {
  background: var(--accent-green);
}

@media screen and (max-width: 500px) {
  .step p {
    font-size: 11px;
    bottom: -20px;
  }
}
