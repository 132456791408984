.hide {
  display: none;
}

.background {
  background-color: black;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 300;
  animation: fadeIn 0.1s ease-out;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 301; 
  background-color: var(--primary);
  border-radius: 10px;
  animation: scaleUp 0.1s ease-out;
}

.form {
  width: 50vw;
  max-width: 500px;
  padding: 2rem 3rem;
  display: grid;
} 

.header {
  text-align: center;
}

.label {
  color: var(--text-accent);
}

.form > input {
  width: 100%;
}

.time {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
  column-gap: 2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
