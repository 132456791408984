.container {
  width: 1.5rem;
  height: auto;
}

.container > img {
  width: 100%;
  height: auto;
}

.container:hover {
  cursor: pointer;
}
