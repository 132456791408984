.container {
  display: flex;
  align-items: center;
  background-color: var(--primary);
  padding: 1rem 1rem;
  border-radius: 10px;
  transition: opacity 0.2s;
}

.container:hover {
  cursor: pointer;
  opacity: 0.7;
}

.info {
  flex: 3;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 2rem;
}

.date {
  text-align: center;
  padding: 1rem;
  background-color: var(--secondary);
  border-radius: 10px;
}

.day {
  color: var(--accent-orange);
  font-weight: bold;
}

.short {
  color: var(--text-accent);
  font-size: var(--font-350);
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  white-space: nowrap;
}

.hours {
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cash {
  font-weight: bold;
  color: var(--accent-green);
}

.error {
  text-align: center;
  height: 100%;
  font-weight: bold;
  font-size: var(--font-500);
  color: var(--accent-red);
}

.selected {
  border: 1px solid var(--accent-orange);
}

.grayed {
  color: var(--text-accent);
}

.holiday {
  color: var(--accent-yellow);
}
