.widgets {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  overflow-y: scroll;
  height: 100vh;
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
  margin-top: 1rem;
  padding-bottom: 5rem;
}

.widgets > div {
  max-width: 800px;
  width: 100%;
}

.header {
  font-weight: normal;
  margin-bottom: 2rem;
}

.upcoming > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 2rem;
  column-gap: 2rem;
}

.show {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.cal {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1rem;
  margin-top: 1rem;
  z-index: 5;
}

.hide {
    display: none;
}

.icon {
  margin-left: 1rem;
  margin-top: 1rem;
  z-index: 5;
}
