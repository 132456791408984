.old {
  /* Text Sizes */
  --font-350: 1rem;
  /* 16 */
  --font-400: 1.125rem;
  /* 18 */
  --font-500: 1.5rem;
  /* 24 */
  --font-700: 2.25rem;
  /* 36 */
  --font-800: 3rem;
  /* 48 */
}

:root {
  --font-350: calc(var(--font-400) - .125rem);
  --font-400: 1rem;
  --font-500: calc(var(--font-400) + .125rem);
  --font-700: calc(var(--font-400) + .5rem);
  --font-800: calc(var(--font-400) + 1.25rem);
  --font-900: calc(var(--font-400) + 1.5rem);

  --primary: #181a23;
  --primary-secondary: #1e212a;
  --primary-tertiary: #292D38;
  --secondary: #111118;
  --accent-orange: #f09581;
  --accent-green: #76d27e;
  --accent-yellow: #ffd763;
  --accent-red: #f04b4b;
  --text-primary: #ffffff;
  --text-accent: #808080;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
  transition: font-size 0.1s;
}


h1 {
  font-size: var(--font-800);
}

h2 {
  font-size: var(--font-700);
}

h3 {
  font-size: var(--font-500);
  font-weight: 400;
}

p,
select,
button,
div {
  font-size: var(--font-400);
  color: var(--text-primary);
}

h1,
h2,
h3,
h4,
h5,
p {
  color: var(--text-primary);
}

body {
  background-color: var(--primary);
  height: 100dvh;
}

.content {
  position: fixed;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: auto 1fr auto;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
  background-color: var(--accent-green);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-orange);
}

.hide {
  display: none;
}
